import { HttpErrorResponse } from '@angular/common/http';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Placa } from 'src/app/models/placa.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-gestio',
  templateUrl: './gestio.component.html',
  styleUrls: ['./gestio.component.css']
})

export class GestioComponent implements OnInit {

  user:User;
  updatingUser:boolean = false;
  places:Placa[];
  places_comerciant:Placa[];
  tabla_comerciant:Placa[];
  tabla_vehicles:Placa[];

  filterForm_taula_comerciant :FormGroup;
  filterForm_taula_vehicles :FormGroup;

  constructor(private userService:UserService,
              private apiService: ApiService,
              private authService: AuthenticationService,
              private toastr: ToastrService,
              private fb: FormBuilder,
              private router: Router) {
    this.filterForm_taula_comerciant = fb.group({
      page_size: [5, Validators.required],
      page_number: [0, Validators.required],
      user_id: null,
      comerciant: true,
      start_date: null,
      end_date: null,
      search: null,
      status: [0, Validators.required],
      total: [0]
    });
    this.filterForm_taula_vehicles = fb.group({
      page_size: [5, Validators.required],
      page_number: [0, Validators.required],
      user_id: null,
      comerciant: true,
      start_date: null,
      end_date: null,
      search: null,
      status: [0, Validators.required],
      total: [0]
    });
  }

  ngOnInit(): void {
    this.userService.currentUser.subscribe( u => {
      if(u==null) this.router.navigate(["/login"], { queryParams: { start: 1} });
      this.user = u;
      this.places = u.places.filter(p => p.Comerciant==false && p.Baixa==false);
      this.places_comerciant = []; // u.places.filter(p => p.Comerciant==true && p.Baixa==false);
      this.filterForm_taula_comerciant = this.fb.group({
        page_size: [5, Validators.required],
        page_number: [0, Validators.required],
        user_id: u?.id,
        comerciant: true,
        start_date: null,
        end_date: null,
        search: null,
        status: [0, Validators.required],
        total: [0]
      });
      this.filterForm_taula_vehicles = this.fb.group({
        page_size: [5, Validators.required],
        page_number: [0, Validators.required],
        user_id: u?.id,
        comerciant: false,
        start_date: null,
        end_date: null,
        search: null,
        status: [0, Validators.required],
        total: [0]
      });
      this.fetchPlaces(u.id,false);
      this.fetchPlaces(u.id,true);
    });
  }

  fetchPlaces(user_id:number, comerciant:boolean) {
    if (comerciant){
      this.filterForm_taula_comerciant.patchValue({
        comerciant: true
      })
      this.apiService.getPlaces(this.filterForm_taula_comerciant.value).subscribe(
        data => {
          this.tabla_comerciant = data.data;
          
          this.filterForm_taula_comerciant.patchValue({
            total: +data.total,
            page_number: +data.page_number,
            user_id: user_id,
            comerciant: true,
            page_size: +data.page_size
          });
        }
      );
    } else {
      this.filterForm_taula_vehicles.patchValue({
        comerciant: false
      })
      this.apiService.getPlaces(this.filterForm_taula_vehicles.value).subscribe(
        data => {
          this.tabla_vehicles = data.data;
          
          this.filterForm_taula_vehicles.patchValue({
            total: +data.total,
            page_number: +data.page_number,
            user_id: user_id,
            comerciant: false,
            page_size: +data.page_size
          });
        }
      );
    }

  }

  updateUser() {
    var l = document.getElementById("loader");
    l.style.display = "flex";
    this.apiService.updateUser(this.user.id,this.user).subscribe( res => {
      l.style.display = "none";
      this.updatingUser = false;
      this.userService.setUser(res);
    },
    err => {
      l.style.display = "none";
    })
  }

  dateFormat(d:Date) {
    if (d == null){
      return "error";
    } else {
      return d[8]+d[9]+"/"+d[5]+d[6]+"/"+d[0]+d[1]+d[2]+d[3];
    }
  }

  addPlaca(comerciant:boolean = false) {
    var p = new Placa();
    p.creating = true;
    p.Comerciant = comerciant;
    if(comerciant) this.places_comerciant.push(p);
    else this.places.push(p);
  }

  updatePlaca(i:number, comerciant:boolean) {
    var p:Placa;

    if(comerciant) {
      p = this.places_comerciant[i];
    } else {
      p = this.places[i];
    }

    if(!p.Matricula) {
      this.toastr.error("El camp matrícula està buit.", "Error alta matrícula");
      return;
    }

    p.Comerciant = comerciant;
    p.updated = true;

    var index = this.user.places.indexOf(p);
    
    if (index < 0) {
      index = this.user.places.push(p) - 1;
    }

    if(index >= 0 && !comerciant) this.places[i] = p;
    if(index >= 0 && comerciant) this.places_comerciant[i] = p;

    this.updateUser();

    if (comerciant) {
      this.places_comerciant = [];
    }
  }

  deletePlaca(i:number, comerciant:boolean) {
    var p:Placa;

    if(comerciant) {
      p = this.places_comerciant[i];
    } else {
      p = this.places[i];
    }

    if((p.Matricula!=null && p.Matricula!='') || (p.id != null && p.id > 0)) {
      p.Baixa = true;
      p.delete = true;
      var index = this.user.places.indexOf(p);
      if(index >= 0) this.places[i] = p;
      this.updateUser();
    } else {
      // this.user.places.slice(i,1);
      this.places[i].delete = true;
    }
  }

  cancelPlaca(i:number, comerciant:boolean) {
    // this.user.places = this.user.places.filter(placa => !(placa.creating && placa.Comerciant === comerciant));
    if(comerciant) {
      this.places_comerciant = [];
    } else {
      this.places = this.places.filter(placa => !placa.creating);
    }
  }

  logout() {
    this.authService.logout();
  }

  nextPage(comerciant:boolean) {
    if (comerciant){
      this.changePage((+this.filterForm_taula_comerciant.value['page_number'])+1,comerciant);
    } else {
      this.changePage((+this.filterForm_taula_vehicles.value['page_number'])+1,comerciant);
    }
  }

  previousPage(comerciant:boolean) {
    if (comerciant){
      this.changePage((+this.filterForm_taula_comerciant.value['page_number'])-1,comerciant);
    } else {
      this.changePage((+this.filterForm_taula_vehicles.value['page_number'])-1,comerciant);
    }
  }

  private changePage(page_num:number, comerciant:boolean) {
    if (comerciant){
      this.filterForm_taula_comerciant.patchValue({ page_number: page_num });
    } else {
      
      this.filterForm_taula_vehicles.patchValue({ page_number: page_num });
    }
    this.fetchPlaces(this.user.id,comerciant);
  }

  changeTableGeneric(comerciant:boolean) {
    this.fetchPlaces(this.user.id,comerciant);
  }

  checkIfOpen(p:Placa) : boolean {
    if (!p.Comerciant){
      return !p.Baixa;
    } else if (p.DataAlta != null && p.HoraAlta != null && p.HoraBaixa != null)  {
      var today : Date = new Date(); 
      var da : Date = new Date(p.DataAlta.toString());
      var db : Date = new Date(p.DataAlta.toString());
      
      da.setHours(Number(p.HoraAlta.toString().substring(0,2)),
      Number(p.HoraAlta.toString().substring(3,5)));
      db.setHours(Number(p.HoraBaixa.toString().substring(0,2)),
      Number(p.HoraBaixa.toString().substring(3,5)))
      
      return da.getTime() < today.getTime() && db.getTime() > today.getTime() ;
    } else {
      return false;
    }
  }

  filterSearch(text:string, comerciant:boolean){
   
    if (comerciant){
      this.filterForm_taula_comerciant.patchValue({ search: text });
    } else {
      
      this.filterForm_taula_vehicles.patchValue({ search: text });
    }
    this.fetchPlaces(this.user.id,comerciant)
  }

  timeout: any = null;
  onFilterSearch(event: any, text:string, comerciant:boolean) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(() => {
      if (event.keyCode != 13) {
        this.filterSearch(text,comerciant);
      }
    }, 500);
  }

  clearFilters(comerciant: boolean){
    if(comerciant){
      this.filterForm_taula_comerciant.patchValue({ search: null });
      this.filterForm_taula_comerciant.patchValue({ start_date: null });
      this.filterForm_taula_comerciant.patchValue({ end_date: null });
      this.filterForm_taula_comerciant.patchValue({ status: 0 });
    } else {
      this.filterForm_taula_vehicles.patchValue({ search: null });
      this.filterForm_taula_vehicles.patchValue({ start_date: null });
      this.filterForm_taula_vehicles.patchValue({ end_date: null });
      this.filterForm_taula_vehicles.patchValue({ status: 0 });
    }
    this.fetchPlaces(this.user.id,comerciant)
  }
}
