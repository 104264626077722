import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/usuari/login/login.component';
import { RegisterComponent } from './components/usuari/register/register.component';
import { GestioComponent } from './components/usuari/gestio/gestio.component';
import { AppGuard } from './guards/app.guard';
import { MainComponent } from './components/gestor/main/main.component';
import { ChangePasswordComponent } from './components/usuari/change-password/change-password.component';

const routes: Routes = [

  { path: '', component: LoginComponent, data: { title: "Login" } },
  { path: 'login', component: LoginComponent, data: { title: "Login" } },
  { path: 'register', component: RegisterComponent, data: { title: "Register" } },
  { path: 'gestio', canActivate: [ AppGuard ], component: GestioComponent, data: { title: "Gestio" } },
  { path: 'GestorMat', canActivate: [ AppGuard ], component: MainComponent, data: { title: "GestorMat" } },
  { path: 'change-password', component: ChangePasswordComponent, data: { title: 'Restableix la contrasenya' } },
  { path: '**', pathMatch: 'full', redirectTo: 'gestio' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
