<div id="main">
    <div id="container">
        <h1>AJUNTAMENT DE PALS</h1>
    
        <form id="login-form" [formGroup]="form" (ngSubmit)="register()">
            <h2>Inici de sessió</h2>

            <div>
                <span class="icon-input"><i class="fa fa-user fa-1x"> </i></span>
                <input type="text" placeholder="Usuari" name="email" formControlName="email" required>
            </div>
    
            <div>
                <span class="icon-input"><i class="fa fa-lock fa-1x"> </i></span>
                <input id="password" type="password" placeholder="Contrasenya" formControlName="password" name="password" required>
            </div>
            <span class="little"><a>He oblidat la contrasenya</a></span>
    
            <button type="submit" class="btn-blue">INICIAR SESSIÓ</button>
            <br>
            <span id="register-redirection" class="little" style="text-decoration: none; margin: 0px auto; font-style: italic;">Ja ets usuari? Fes LogIn</span>
        </form>

    </div>
</div>