import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  token_provided:boolean = false;
  token:string;
  password:string;
  confirm_password:string;

  constructor(private api:ApiService,
              private router:Router,
              private activatedRoute:ActivatedRoute,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParamMap.get("token");
    this.token_provided = this.token ? true : false;
  }

  change() {
    if(this.confirm_password!=this.password) this.toastr.error("Si us plau, torni a intentar-ho", "Les contrasenyes no coincideixen.");
    else {
      this.api.changePassword(this.token, this.password).subscribe(
        data => {
          this.toastr.success("Contrasenya actualitzada correctament", "");
          this.router.navigate(["/login"]);
        },
        err => {
          this.toastr.error("Token no vàlid", "");
        }
      );
    }
  }

}
