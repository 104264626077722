
<button class="btn-l-blue"  (click)="logout()">Tancar sessió</button>
<div id="header" style="margin-bottom: 1%;">
    <img src="https://images.gestionaweb.cat/1942/pqimg-640-640/logo-header02.png">
</div>

<div id="main">
    <div id="header">
        <h2 *ngIf="!show_hist"  style="margin: 0px;">Gestió d’usuaris</h2>
        <h2 *ngIf="show_hist"  style="margin: 0px;">Historial de matrícules</h2>
        <div class="buttons-container">
            <button  *ngIf="!show_hist" class="btn-blue" (click)="show_dialog_new_user = true; selected_user = null" style="margin: 0px;">AFEGIR USUARI</button>
            <button  *ngIf="!show_hist" class="btn-blue" (click)="show_hist = true" style="margin: 0px;">HISTORIAL MATRÍCULES</button>
            <button  *ngIf="show_hist" class="btn-blue" (click)="show_hist = false" style="margin: 0px;">LLISTAT USUARIS</button>
        </div>
    </div>

    
    <div id="cerca" *ngIf="!show_hist" >
        <input type="text" placeholder="Cerca" (input)="filterUsers($event.target.value)">
        <i class="fa fa-search fa-2x" style="cursor: pointer; position: relative; right: 30px; font-size: 18px; width: 0px;"></i>
    </div>
    <div id="container" *ngIf="!show_hist">
        <h2>Usuaris</h2>

        <div id="table-container">
            <table>
                <thead>
                    <tr>
                        <th style="cursor: pointer;">
                            <span (click)="changeOrder('Nom')">Nom</span>
                            <i *ngIf="order_by == 'Nom' && order_direction == 'ASC'" style="margin-left: 5px;" (click)="changeOrder('Nom')" class="fa fa-chevron-up fa-1x"></i>
                            <i *ngIf="order_by == 'Nom' && order_direction == 'DESC'" style="margin-left: 5px;" (click)="changeOrder('Nom')" class="fa fa-chevron-down fa-1x"></i>
                        </th>
                        <th>Comerciant</th>
                        <th style="cursor: pointer;">
                            <span (click)="changeOrder('NumPlaces')">Places</span>
                            <i *ngIf="order_by == 'NumPlaces' && order_direction == 'ASC'" style="margin-left: 5px;" (click)="changeOrder('NumPlaces')" class="fa fa-chevron-up fa-1x"></i>
                            <i *ngIf="order_by == 'NumPlaces' && order_direction == 'DESC'" style="margin-left: 5px;" (click)="changeOrder('NumPlaces')" class="fa fa-chevron-down fa-1x"></i></th>
                        <th>Opcions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of users; let i = index;" (click)="showAll(i)" style="cursor: pointer;" #row>
                        <td>
                            <span>{{ u.Nom + ' ' + u.Cognoms }}</span>
                            <div *ngIf="u.show_all" class="extra_info">
                                <span>{{ u.NIF }}</span>
                                <span>{{ u.Mobil + '-' + u.Telefon }}</span>
                            </div> 
                        </td>
                        <td>
                            <span *ngIf="u.Comerciant"><i class="fa fa-check fa-1x"> </i></span>
                            <div *ngIf="u.show_all" class="extra_info">
                                <span>{{ u.email }}</span>
                                <span>{{ u.CodiPostal }} </span>
                                <span>{{ u.Adreca }}</span>
                            </div> 
                        </td>
                        <td>
                            <span>{{ u.NumPlaces }}</span>
                        </td>
                        <!--td>
                            <span *ngFor="let p of u.places">
                                <span class="matricula-span" *ngIf="!p.Baixa">
                                    {{ p.Matricula }}
                                </span>
                            </span>
                        </td-->
                        <td>
                            <div *ngIf="!u.show_all">
                                <i  class="fa fa-chevron-down" ></i>
                            </div>
                            <div *ngIf="u.show_all" >
                                <i class="fa fa-chevron-up"></i>
                            </div>
                            <div *ngIf="u.show_all" class="extra_info" style="text-align: right;">
                                <div class="buttons-container">
                                    <button *ngIf="u.id!=me.id" class="btn-red" (click)="selected_user = u; deleteUser();" >ELIMINAR</button>
                                    <button class="btn-blue" (click)="show_dialog_new_user = true; selected_user = u" >EDITAR</button>
                                    <button class="btn-blue" (click)="show_dialog_user_history = true; selected_user = u">HISTORIAL</button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="filter">
            <form [formGroup]="filterForm">
                <div class="paginator">
                    <div class="items-per-page">
                        <label for="page_size">Elementos por página</label>
                        <select id="page_size" formControlName="page_size" (change)="changePageSize()">
                            <option [value]="+10">10</option>
                            <option [value]="+25">25</option>
                            <option [value]="+50">50</option>
                        </select>
                    </div>

                    <button class="grey icon" [disabled]="filterForm.value['page_number']==0"
                        (click)="previousPage()"><i class="fas fa-chevron-left"></i></button>
                    <button class="grey icon"
                        [disabled]="(filterForm.value['page_number']+1)*filterForm.value['page_size'] >= filterForm.value['total']"
                        (click)="nextPage()"><i class="fas fa-chevron-right"></i></button>
                    <span class="pagination-counter">{{
                        ((filterForm.value['page_number']+1)*filterForm.value['page_size'])-filterForm.value['page_size']+1
                        }} - {{ (filterForm.value['page_number']+1)*filterForm.value['page_size'] >
                        filterForm.value['total'] ? filterForm.value['total'] :
                        (filterForm.value['page_number']+1)*filterForm.value['page_size']}} de {{
                        filterForm.value['total'] }}</span>
                </div>
            </form>
        </div>
    </div>
    
    <div class="container-history">
        <div id="searcher" *ngIf="show_hist">
            <form [formGroup]="filterForm_taula_vehicles">
                <div class="filters-wrapper">
                    <div class="filters-items">
                        <mat-form-field class="search-item" appearance="outline">
                            <mat-label>Búsqueda</mat-label>
                            <input matInput placeholder="Matrícula, nom, email..." formControlName="search" (input)="this.onFilterSearch($event,$event.target.value)">
                        </mat-form-field>
                        
                        <mat-form-field class="search-item"  appearance="outline" >
                            <mat-label>Interval matrícula oberta</mat-label>
                            <mat-date-range-input  [rangePicker]="picker2">
                                <input matStartDate placeholder="Inici" formControlName="start_date"> 
                                <input matEndDate placeholder="Fi" formControlName="end_date" (dateChange)="changeTableGeneric()">
                                </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>

                            <mat-date-range-picker #picker2></mat-date-range-picker>
                        </mat-form-field>

                        <mat-form-field class="search-item items-type"  appearance="outline">
                            <mat-label for="status">Estat actual: </mat-label>
                            <select id="status"  matNativeControl formControlName="status" (change)="changeTableGeneric()" >
                                <option value="0" default>Tot</option>
                                <option value="1" >Obertes</option>
                                <option value="2" >Tancades</option>
                            </select>
                        </mat-form-field>

                        <mat-form-field class="search-item items-type"  appearance="outline">
                            <mat-label for="comerciant">Tipus: </mat-label>
                            <select id="comerciant"  matNativeControl formControlName="comerciant" (change)="changeTableGeneric()" >
                                <option *ngFor="let opt of comerciant_options" [ngValue]="opt.value">
                                    {{opt.viewValue}}
                                </option>
                                <!--option value={{0}} default>Totes</option>
                                <option value={{1}} > Permanent</option>
                                <option value={{2}} > Temporal</option-->
                            </select>
                        </mat-form-field>

                    </div>
                    <div>
                        <button class="reset-search-button" (click)="clearFilters()"><i class="fas fa-redo"></i></button>
                        <button class="reset-search-button" (click)="export()" style="margin-left: 1em;"><i class="fas fa-download"></i></button>
                    </div>

                </div>
            </form>
        </div>
        <div id="table-container" *ngIf="show_hist" class="tablewidth">
            <table>
                <thead>
                    <tr>
                        <th>Usuari</th>
                        <th>Dades de contacte</th>
                        <th>Matrícula</th>
                        <th>Data Alta</th>
                        <th>Hora Inici</th>
                        <th>Data Baixa</th>
                        <th>Hora Fi</th>
                        <th>Estat Actual</th>
                        <th>Tipus matrícula</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let p of tabla_vehicles; let i = index;" #row>
                        <td>
                            <span>{{ p.Nom + " " + p.Cognoms }}</span>
                        </td>
                        <td>
                            <span>{{ p.Mobil }}</span>
                            <span>{{ p.Telefon }}</span>
                            <span>{{ p.email }}</span>
                        </td>
                        <td>
                            <span>{{ p.Matricula }}</span>
                        </td>
                        <td>
                            <span>{{ dateFormat(p.DataAlta) }}</span>
                        </td>
                        <td>
                            <span>{{ p.HoraAlta }}</span>
                        </td>
                        <td>
                            <span *ngIf="!showEndDate(p)"> - </span>
                            <span *ngIf="showEndDate(p)">{{ dateFormat(p.DataBaixa) }}</span>

                        </td>
                        <td>
                            <span *ngIf="!showEndDate(p)"> - </span>
                            <span *ngIf="showEndDate(p)">{{ p.HoraBaixa }}</span>
                            
                        </td>
                        <td>
                            <span *ngIf="checkIfOpen(p)" style="color: #3D7109DE">OBERT</span>
                            <span *ngIf="!checkIfOpen(p)" style="color: #B71D1DDE">TANCAT</span>
                        </td>
                        <td>
                            <span *ngIf="checkIfComerciant(p)">MERCADERIA</span>
                            <span *ngIf="!checkIfComerciant(p)">PROPIA</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="filter" style="width: 99%;" *ngIf="show_hist">
            <form [formGroup]="filterForm_taula_vehicles">
                <div class="paginator">
                    <div class="items-per-page">
                        <label for="page_size">Elements</label>
                        <select id="page_size" formControlName="page_size" (change)="changeTableGeneric()">
                            <option [value]="+10">10</option>
                            <option [value]="+25">25</option>
                            <option [value]="+50">50</option>
                        </select>
                    </div>
                    
                    <button class="grey icon" [disabled]="filterForm_taula_vehicles.value['page_number']==0" (click)="previousPageV()"><i class="fas fa-chevron-left"></i></button>
                    <button class="grey icon" [disabled]="(filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size'] >= filterForm_taula_vehicles.value['total']" (click)="nextPageV()"><i class="fas fa-chevron-right"></i></button>
                    <span class="pagination-counter">{{ ((filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size'])-filterForm_taula_vehicles.value['page_size']+1 }} - {{ (filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size'] > filterForm_taula_vehicles.value['total'] ? filterForm_taula_vehicles.value['total'] : (filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size']}} de {{ filterForm_taula_vehicles.value['total'] }}</span>
                    
                </div>
            </form>
        </div>
    </div>


</div>
    

<app-new-user
    *ngIf="show_dialog_new_user"
    [user]="selected_user"
    (create)="create($event)"
    (update)="update($event)"
></app-new-user>

<app-user-history
    *ngIf="show_dialog_user_history"
    [user]="selected_user"
    (create)="createUserHistory($event)"
></app-user-history>