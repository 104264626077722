import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { ApiService } from './api.service';
import { TokenService } from './token.service';
import { UserService } from './user.service';

import { map, catchError } from 'rxjs/operators';
import { User } from '../models/user.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private loggedIn = new BehaviorSubject<boolean>(this.token.loggedIn());
  private psw;
  authStatus = this.loggedIn.asObservable();

  constructor(private token:TokenService,
              private api:ApiService,
              private userService:UserService,
              private router:Router) { }
  
  login(username: string, password: string) {
    this.psw = password;
    return this.api.login(username, password).pipe(
      map((response:any) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if(response.user && response.access_token) {
          localStorage.setItem("user_id", response.user.id);
          this.token.handle(response.access_token);
          this.userService.setUser(response.user);
          this.changeAuthStatus(true);
          
        } 
        
        return response;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user_id");
    this.token.remove();
    this.userService.deleteUser();
    this.changeAuthStatus(false);
    this.router.navigate(["/login"]);
  }

  refreshToken() {
    return this.api.getUser(Number(localStorage.getItem("user_id"))).pipe(
      map(data => {
        this.userService.setUser(data);
        this.changeAuthStatus(true);
        return true;
      }),
      catchError(err => {
        return of(false);
      })
    );
    return this.api.refreshToken().pipe(
      map(response => {
        this.userService.setUser(response.user);
        this.token.handle(response.access_token);
        this.changeAuthStatus(true);
        return true;
      }),
      catchError(err => {
        return of(false);
      })
    );
  }

  private changeAuthStatus(value:boolean) {
    this.loggedIn.next(value);
  }
}
