<div id="main">
    <div id="container">
        <h1>AJUNTAMENT DE PALS</h1>
        
        <form #f="ngForm" (ngSubmit)="change()" id="login-form">
            <h2>Canvia la teva contrasenya</h2>
            <div id="inputs">
                <input [(ngModel)]="token" name="token" type="text" placeholder="Token" required id="token" [disabled]="token_provided" required>
                <input [(ngModel)]="password" name="password" type="password" placeholder="Nova contraseña" required id="password">
                <input [(ngModel)]="confirm_password" name="confirm_password" type="password" placeholder="Repeteix la contraseña" required id="confirm_password">
            </div>
            <span class="little" style="margin: 0px auto; font-style: italic;"><a [routerLink]="['/auth', 'login']">Inici de sessió</a></span>
            <button class="btn-blue" type="submit" [disabled]="!f.valid">Canvia contrasenya</button>
        </form>
    </div>
</div>