import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Placa } from 'src/app/models/placa.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

  @Output() create:EventEmitter<any> = new EventEmitter();
  @Output() update:EventEmitter<any> = new EventEmitter();
  @Input() user:User;
  form:FormGroup;
  create_new: boolean = true;
  places:Placa[];
  placesGroup:FormArray;
  emails: string[];
  nifs: string[];

  constructor(private fb: FormBuilder,
    private api:ApiService,
    private toastr: ToastrService) {
    this.form = fb.group({
      Nom: ['', Validators.required],
      Cognoms: ['', Validators.required],
      NIF: [' ', Validators.required],
      Comerciant: [true, Validators.required],
      Tecnic: [false, Validators.required],
      Baixa: [false, Validators.required],
      Mobil: [' ', Validators.required],
      Telefon: [' ', Validators.required],
      email: ['', Validators.required],
      password: new FormControl({value: Math.random().toString(36).slice(-8), disabled: true}, Validators.required),
      Adreca: [' ', Validators.required],
      CodiPostal: [' ', Validators.required],
      Poblacio: [' ', Validators.required],
      NumPlaces: [3, Validators.required],
      DataAlta: [new Date(), Validators.required],
      DataBaixa: [new Date(), Validators.required],
    });
  }

  ngOnInit(): void {
    if(this.user!=null) {

      this.create_new = false;
      this.places = this.user.places.filter( p => !p.Baixa );
      this.placesGroup = this.fb.array(this.getPlaces().map(p => this.fb.group(p)));

      this.form = this.fb.group({
        Nom: [this.user.Nom, Validators.required],
        Cognoms: [this.user.Cognoms, Validators.required],
        NIF: [this.user.NIF, Validators.required],
        Comerciant: [this.user.Comerciant, Validators.required],
        Tecnic: [this.user.Tecnic, Validators.required],
        Baixa: [this.user.Baixa, Validators.required],
        Mobil: [this.user.Mobil, Validators.required],
        Telefon: [this.user.Telefon, Validators.required],
        email: [this.user.email, Validators.required],
        password: new FormControl({value: '*******', disabled: true}, Validators.required),
        Adreca: [this.user.Adreca, Validators.required],
        CodiPostal: [this.user.CodiPostal, Validators.required],
        Poblacio: [this.user.Poblacio, Validators.required],
        NumPlaces: [this.user.NumPlaces, Validators.required],
        DataAlta: [this.user.DataAlta, Validators.required],
        DataBaixa: [this.user.DataBaixa, Validators.required],
        Places: this.placesGroup
      });
    }

    this.api.getUsersEmail().subscribe( e => {
      this.emails = e;
    });

    this.api.getUsersNIF().subscribe( e => {
      this.nifs = e;
    });
  }

  getPlaces() {
    const placaControlArray = [];
    for (let i = 0; i < this.places.length; i++) {
      placaControlArray.push({ 
        Matricula: [this.places[i].Matricula, Validators.required],
      });
    }
    return placaControlArray;
  }

  submit() {
    if(this.create_new) {
      var u = this.form.value as User;
      if(u.Nom=="" || u.Cognoms=="" || u.email=="") {
        this.toastr.error("Has d'introduir el nom, els cognoms i el correu com a mínim.", "");
      } else if(u.NIF!=null && u.NIF!=" " && this.nifs.indexOf(u.NIF)>-1) {
        this.toastr.error("Ja existeix un usuari amb aquest NIF.", "");
      } else if(this.emails.indexOf(u.email)>-1) {
        this.toastr.error("Ja existeix un usuari amb aquest correu.", "");
      } else {
        u.password = Math.random().toString(36).slice(-8);
        this.create.emit(u);
      }
    } else {
      this.update.emit(this.form.value as User);
    }
  }

  cancel() {
    this.create.emit(null);
  }

  sendPasswordResetLink() {
    if(this.form.value["email"]==null || this.form.value["email"]=="") {
      this.toastr.error("Si us plau, entri el seu correu i torni a provar.", "");
    } else {
      var l = document.getElementById("loader");
      l.style.display = "flex";
      this.api.sendPasswordResetLink(this.form.value["email"]).subscribe(
        res => {
          l.style.display = "none";
          this.toastr.success("Correu enviat.", "");
        },
        (error:HttpErrorResponse) => {
          l.style.display = "none";
          if(error.status==401) {
            this.toastr.error("Si us plau, torni a intentar-ho", "Correu no vàlid.");
          }
          else {
            this.toastr.error("Torna a intentar-ho més tard", "Hi ha un error al servidor.");
          }
        }
      );

    }
  }

}
