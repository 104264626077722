import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AnyForUntypedForms } from '@angular/forms';
import { Router } from '@angular/router';
import { Placa } from 'src/app/models/placa.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.css']
})
export class UserHistoryComponent implements OnInit {

  @Output() create:EventEmitter<any> = new EventEmitter();
  @Output() update:EventEmitter<any> = new EventEmitter();
  @Input() user:User;

  filterForm_taula_vehicles :FormGroup;
  tabla_vehicles:Placa[];
  comerciant_options:{ viewValue: string; value: boolean; }[];


  constructor(private userService:UserService,
              private apiService: ApiService,
              private fb: FormBuilder,
              private router: Router
              ) 
  {
    this.comerciant_options = [
      {
        viewValue: "Tot",
        value: null
      },
      {
        viewValue: "Propies",
        value: false
      },
      {
        viewValue: "Mercaderies",
        value: true
      }
    ];

    this.filterForm_taula_vehicles = fb.group({
      page_size: [5, Validators.required],
      page_number: [0, Validators.required],
      user_id: null,
      comerciant: null,
      start_date: null,
      end_date: null,
      search: null,
      status: [0, Validators.required],
      total: [0]
    });
  }

  ngOnInit(): void {
    this.userService.currentUser.subscribe( u => {
      if(u==null) this.router.navigate(["/login"], { queryParams: { start: 1} });
      
      this.filterForm_taula_vehicles = this.fb.group({
        page_size: [5, Validators.required],
        page_number: [0, Validators.required],
        user_id: this.user?.id,
        comerciant: null,
        start_date: null,
        end_date: null,
        search: null,
        status: [0, Validators.required],
        total: [0]
      });
      this.fetchPlaces(this.user?.id);
    });
  }

  fetchPlaces(user_id:number) {
    console.log("sending: ");
    console.log(this.filterForm_taula_vehicles);
      this.apiService.getPlaces(this.filterForm_taula_vehicles.value).subscribe(
        data => {
          this.tabla_vehicles = data.data;
          
          this.filterForm_taula_vehicles.patchValue({
            total: +data.total,
            page_number: +data.page_number,
            user_id: user_id,
            page_size: +data.page_size
          });

          console.log("getting: " );
          console.log( data.data);
        }
      );
  }

  changeTableGeneric() {
    this.fetchPlaces(this.user.id);
  }

  clearFilters(){
    this.filterForm_taula_vehicles.patchValue({ search: null });
    this.filterForm_taula_vehicles.patchValue({ start_date: null });
    this.filterForm_taula_vehicles.patchValue({ end_date: null });
    this.filterForm_taula_vehicles.patchValue({ status: 0 });
    this.filterForm_taula_vehicles.patchValue({ comerciant: null})
    this.fetchPlaces(this.user.id)
  }

  filterSearch(text:string){
    this.filterForm_taula_vehicles.patchValue({ search: text });
    this.fetchPlaces(this.user.id)
  }

  timeout: any = null;
  onFilterSearch(event: any, text:string) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(() => {
      if (event.keyCode != 13) {
        this.filterSearch(text);
      }
    }, 500);
  }

  cancel() {
    this.create.emit(null);
  }

  dateFormat(d:Date) {
    return d[8]+d[9]+"/"+d[5]+d[6]+"/"+d[0]+d[1]+d[2]+d[3];
  }

  checkIfOpen(p:Placa) : boolean {
    if (!p.Comerciant){
      return !p.Baixa;
    } else{
      var today : Date = new Date(); 
      var da : Date = new Date(p.DataAlta.toString());
      var db : Date = new Date(p.DataAlta.toString());
      
      da.setHours(Number(p.HoraAlta.toString().substring(0,2)),
      Number(p.HoraAlta.toString().substring(3,5)));
      db.setHours(Number(p.HoraBaixa.toString().substring(0,2)),
      Number(p.HoraBaixa.toString().substring(3,5)))
      
      return da.getTime() < today.getTime() && db.getTime() > today.getTime() ;
    }     
  }

  export() {
    if (this.user != null){
      const today = new Date();
      const yyyy = today.getFullYear().toString();
      let mm = (today.getMonth() + 1).toString(); // Months start at 0!
      let dd = today.getDate().toString();

      if (today.getDate() < 10) dd = '0' + dd;
      if (today.getMonth() + 1 < 10) mm = '0' + mm;
      this.exportexcel("LlistatMatriqulesParcial-" + yyyy + mm + dd + "_" + this.user.Nom + "-" + this.user.Cognoms);
    }
  }

  exportexcel(fileName: string) : void {
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        let map = new Map<string, string>() 
        let jsonObject = {};  
        map.forEach((value, key) => {  
            jsonObject[key] = value  
        }); 
        //const dades: any[] = map(this.tabla_vehicles, (history) => { Nom: history.Nom, Cognom: history.cognom   });

        var taulaexcel = this.tabla_vehicles.map((elem) => ({
            Matricula : elem.Matricula,
            DataAlta : elem.DataAlta.toString().substring(0,10),
            HoraInici : elem.DataAlta.toString().substring(11,19),
            DataBaixa : (!elem.Comerciant && !elem.Baixa) ? "-" : elem.DataBaixa.toString().substring(0,10),
            HoraFi : (!elem.Comerciant && !elem.Baixa) ? "-" : elem.DataBaixa.toString().substring(11,19),
            Comerciant : elem.Comerciant ? "COMERCIANT" : "PROPIA"
          }
        ));

        var ws = XLSX.utils.json_to_sheet(taulaexcel, 
          {header: [ "Matricula", "DataAlta",	"HoraInici",	"DataBaixa",	"HoraFi",	"Comerciant"], 
          skipHeader: false});
      
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, fileName + '.xlsx');
  
  }

  checkIfComerciant(p:Placa) : boolean {
    return p.Comerciant;
  }

  nextPage() {
    this.changePage((+this.filterForm_taula_vehicles.value['page_number'])+1);
  }

  previousPage() {
    this.changePage((+this.filterForm_taula_vehicles.value['page_number'])-1);
  }

  private changePage(page_num:number) {
    this.filterForm_taula_vehicles.patchValue({ page_number: page_num });
    this.fetchPlaces(this.user.id);
  }

  showEndDate(p:Placa):boolean{
    if (!p.Comerciant && !p.Baixa)
      return false;
    else 
      return true;
  }

}
