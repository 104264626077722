import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  form:FormGroup;
  loading:boolean = false;
  user:User = new User();

  constructor(private fb: FormBuilder,
              private auth:AuthenticationService,
              private router:Router,
              private toastr: ToastrService) {
    this.form = fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.user.Adreca = 'asda';
  }

  register() {

  }

  login() {
    this.loading = true;
    this.auth.login(this.form.value["email"], this.form.value["password"]).subscribe(
      res => {
        this.toastr.success("Has iniciat sessió correctament.", "Login correcte");
        this.router.navigate(["/gestio"], { queryParams: { start: 1} });
        this.loading = false;
      },
      (error:HttpErrorResponse) => {
        if(error.status==401) {
          this.toastr.error("Si us plau, torni a intentar-ho", "Nom d'usuari o contrasenya no vàlid.");
        }
        else {
          this.toastr.error("Torna a intentar-ho més tard", "Hi ha un error al servidor.");
        }
        //this.form.reset();
        this.loading = false;
      }
    );
  }

}
