import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TokenService } from './token.service';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { environment } from 'src/environments/environment';
import { Placa } from '../models/placa.model';
import { HistoryItem } from '../models/history-item.model';
import { ArrayResponse } from '../models/array-response.interface';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  //private host:string = "http://127.0.0.1:8000/api";
  private host:string = "https://palsmatriculesback.tothomweb.com" + "/api";

  constructor(private http:HttpClient) {
  }

  public getHost() {
    return this.host;
  }

  private setParams(filters:any, params:HttpParams = new HttpParams()) {
    Object.entries(filters).forEach((val:[string,any]) => {
      if(val[1]!=undefined && val[1]!=null) {
        if(val[1] instanceof Date /*|| isMoment(val[1])*/) val[1] = val[1].toISOString();
        else if(typeof val[1] === "boolean") val[1] = val[1] ? 1 : 0;
        params = params.set(val[0], val[1]);
      }
    });

    return params;
  }

  /**
   * -------------------------------------------------- AUTHENTICATION --------------------------------------------------
   */
  me() {
    return this.http.get<any>(`${this.host}/auth/me`); // TODO
  }

  refreshToken() {
    return this.http.post<any>(`${this.host}/auth/refresh`, null, {headers : new HttpHeaders({ 'Accept': 'application/json' })});
  }

  login(email:string, password:string) {
    return this.http.post<any>(`${this.host}/auth/login`, { email: email, password: password });
  }

  sendPasswordResetLink(email:string) {
    return this.http.post<any>(`${this.host}/auth/sendPasswordResetLink`, { email: email });
  }

  changePassword(token:string, password:string) {
    return this.http.post<any>(`${this.host}/auth/changePassword`, { token: token, password: password });
  }

  /**
   * -------------------------------------------------- Users --------------------------------------------------
   */
  getAllUsers(params:any = []) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<User>>(`${this.host}/users`, { params: query });
  }

  getUser(id:number,params:any = []) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<User>(`${this.host}/users/${id}`, { params: query });
  }

  createUser(user:User) {
    return this.http.post<User>(`${this.host}/users`, user);
  }

  updateUser(id_user:number, user:User) {
    return this.http.put<User>(`${this.host}/users/${id_user}`, user);
  }

  deleteUser(id:number) {
    return this.http.delete<any>(`${this.host}/users/${id}`);
  }

  getUsersEmail() {
    return this.http.get<any>(`${this.host}/users-emails`);
  }

  getUsersNIF() {
    return this.http.get<any>(`${this.host}/users-dnis`);
  }

  /**
   * -------------------------------------------------- Plaça --------------------------------------------------
   */
  getPlaces(params:any = []) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Placa>>(`${this.host}/places`, { params: query });
  }

  getHistory(params:any = []) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<HistoryItem>>(`${this.host}/history`, { params: query });
  }
}
