import { Time } from "@angular/common";

export class Placa {
    id:         number;
    Baixa:      boolean;
    Comerciant: boolean;
    DataAlta:   Date;
    DataBaixa:  Date;
    HoraAlta:   Time;
    HoraBaixa:  Time;
    IdUsuari:   number;
    Matricula:  string;
    created_at: Date;
    updated_at: Date;
    updating:   boolean = false;
    creating:   boolean = false;
    delete:     boolean = false;
    updated:     boolean = false;
}