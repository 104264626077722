<div class="dialog-container">
    <form class="dialog" [formGroup]="form" (ngSubmit)="submit()">
        <div>
            <h2>Alta d'usuari</h2>
            <div (click)="cancel()" style="cursor: pointer;">
                <i class="fa fa-times fa-2x"></i>
            </div>
        </div>
        <p class="subtitle">Ompli el següent formulari per crear un nou usuari.</p>
        <div class="dialog-content">
            <div class="section">
                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Nom</mat-label>
                    <input matInput id="Nom" type="text" formControlName="Nom" placeholder="Nom de l'usuari" required/>
                </mat-form-field>
                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Cognoms</mat-label>
                    <input matInput id="Cognoms" type="text" formControlName="Cognoms" placeholder="Cognoms de l'usuari" required/>
                </mat-form-field>

                <mat-form-field  appearance="outline" class="field">
                    <mat-label>NIF</mat-label>
                    <input matInput id="NIF" type="text" formControlName="NIF" placeholder="NIF" required/>
                </mat-form-field>
                <div style="display: flex; flex-direction: column;">
                    <mat-checkbox formControlName="Comerciant">Comerciant</mat-checkbox>
                    <mat-checkbox formControlName="Baixa">Baixa</mat-checkbox>
                </div>

                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Mòbil</mat-label>
                    <input matInput id="Mobil" type="tel" formControlName="Mobil" placeholder="Mòbil" required/>
                </mat-form-field>
                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Telèfon</mat-label>
                    <input matInput id="Telefon" type="tel" formControlName="Telefon" placeholder="Telèfon" required/>
                </mat-form-field>

                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Correu</mat-label>
                    <input matInput id="email" type="text" formControlName="email" placeholder="Correu electrònic" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required/>
                </mat-form-field>
                
                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Clau</mat-label>
                    <input matInput id="password" type="password" formControlName="password" placeholder="Clau"/>
                </mat-form-field>
                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Adreça</mat-label>
                    <input matInput id="Adreca" type="text" formControlName="Adreca" placeholder="Adreça" required/>
                </mat-form-field>
                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Codi Postal</mat-label>
                    <input matInput id="CodiPostal" type="text" formControlName="CodiPostal" placeholder="Codi Postal" required/>
                </mat-form-field>

                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Població</mat-label>
                    <input matInput id="Poblacio" type="text" formControlName="Poblacio" placeholder="Població" required/>
                </mat-form-field>
                <mat-form-field  appearance="outline" class="field">
                    <mat-label>Número de places</mat-label>
                    <input matInput id="NumPlaces" type="number" min="0" formControlName="NumPlaces" placeholder="Número de places" required/>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Data d'Alta</mat-label>
                    <input matInput [matDatepicker]="DataAlta" formControlName="DataAlta" placeholder="DD/MM/YYYY">
                    <mat-datepicker-toggle matSuffix [for]="DataAlta"></mat-datepicker-toggle>
                    <mat-datepicker #DataAlta></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Data de Baixa</mat-label>
                    <input matInput [matDatepicker]="DataBaixa" formControlName="DataBaixa" placeholder="DD/MM/YYYY">
                    <mat-datepicker-toggle matSuffix [for]="DataBaixa"></mat-datepicker-toggle>
                    <mat-datepicker #DataBaixa></mat-datepicker>
                </mat-form-field>

                <div *ngIf="!create_new" [formArrayName]="'Places'" id="matricules-editable">
                    <div *ngFor="let p of placesGroup.controls; let i = index;" class="form-group">
                      <div [formGroupName]="i">
                        <label class="center-block">{{'Plaça' + i}}:
                          <input formControlName="Matricula" class="form-control"/> 
                        </label>
                      </div>
                    </div>
                  </div>
            </div>
            <div class="section buttons">
                <button *ngIf="!create_new" class="btn-blue" style="margin-left: auto; margin-right: 0px;" (click)="sendPasswordResetLink()" type="button">Enviar correu canvi contrasenya</button>
                <button *ngIf="!create_new" class="btn-blue" style="margin-right: 0px;" type="submit">Guardar</button>
                <button *ngIf="create_new" class="btn-blue" style="margin-right: 0px;" type="submit">Guardar</button>
            </div>
        </div>
    </form>
</div>