import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private subjectLoading: BehaviorSubject<boolean> = new BehaviorSubject(false) as any;
  public loading: Observable<boolean> = this.subjectLoading.asObservable(); //amb aixo conectem el subject amb l'observable

  constructor() { }

  /**
   * LOADING OBSERVABLE
   */
  enableLoading() {
    this.subjectLoading.next(true);
  }

  disableLoading() {
    this.subjectLoading.next(false);
  }

}
