import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentUser: Observable<User> = this.currentUserSubject.asObservable();

  constructor() { 
  }

  public getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  public setUser(user:User) {
    if(user!=null && user!=undefined) {
      this.currentUserSubject.next(user);
    }
  }

  public deleteUser() {
    this.currentUserSubject.next(null);
  }
}
