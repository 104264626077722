<div id="header">
    <img style="opacity: 0" src="https://images.gestionaweb.cat/1942/pqimg-640-640/logo-header02.png">
    <h1>AJUNTAMENT DE PALS</h1>
    <img src="https://images.gestionaweb.cat/1942/pqimg-640-640/logo-header02.png">
</div>

<div id="main">
    <div id="container">


        <h2>Gestió de matrícules</h2>
        <div class="dashboard" [class.noComercial]="!user.Comerciant">
            <div style="max-width: 400px;">
                <div class="container-white">
                    <div class="user">
                        <div>
                            <img class="user-photo" src="assets/utils/picture.png">
                        </div>

                        <div>
                            <strong *ngIf="!updatingUser">{{user.Nom}}</strong>
                            <input *ngIf="updatingUser" [(ngModel)]="user.Nom">

                            <p *ngIf="!updatingUser">{{user.CodiPostal}}</p>
                            <input *ngIf="updatingUser" [(ngModel)]="user.CodiPostal">

                            <p *ngIf="!updatingUser">{{user.Telefon}} - {{user.Mobil}}</p>
                            <input *ngIf="updatingUser" [(ngModel)]="user.Telefon">
                            <input *ngIf="updatingUser" [(ngModel)]="user.Mobil">

                            <p *ngIf="!updatingUser">{{user.email}}</p>
                            <input *ngIf="updatingUser" [(ngModel)]="user.email">

                            <p *ngIf="!updatingUser">{{user.Adreca + ' ' + user.CodiPostal + ' ' + user.Poblacio}}</p>
                            <input *ngIf="updatingUser" [(ngModel)]="user.Adreca">
                            <input *ngIf="updatingUser" [(ngModel)]="user.CodiPostal">
                            <input *ngIf="updatingUser" [(ngModel)]="user.Poblacio">

                        </div>
                    </div>
                    <!-- <button *ngIf="!updatingUser" class="btn-l-blue" style="display: block; margin-left: auto; margin-top: 30px;" (click)="updatingUser = !updatingUser">MODIFICAR</button>
                    <button *ngIf="updatingUser" class="btn-l-blue" style="display: block; margin-left: auto; margin-top: 30px;" (click)="updateUser()">Guardar</button> -->
                    <button class="btn-l-blue" style="display: block; margin-left: auto; margin-top: 30px;"
                        (click)="logout()">Tancar sessió</button>
                </div>
                <div class="container-white" *ngIf="places!=null && places_comerciant!=null">
                    Tens assignades {{places.length}} d'aparcament d'un total de {{user.NumPlaces}} <span
                        *ngIf="user.Comerciant">i {{places_comerciant.length}} entrada de mercaderia</span>
                </div>
            </div>

            <div>
                <h3 style="font-weight: 600;" *ngIf="user.Comerciant">Els meus vehicles</h3>
                <div *ngIf="user.Comerciant" style="margin-bottom: 20px;">Els meus vehicles tindrán permesa l’entrada de
                    forma ilimitada</div>
                <div class="matricules" *ngIf="places!=null && places_comerciant!=null">
                    <div *ngFor="let p of places; let i = index" class="container-matricula container-white"
                        [ngClass]="{'updating': p.updating || p.creating}">
                        <div style="display: flex; flex-direction: column; color: #00000099;">
                            <span>Vehicle {{i+1}}</span>
                            <div *ngIf="!p.updating && !p.creating" class="matricula">{{p.Matricula}}</div>
                            <input *ngIf="p.updating || p.creating" class="matricula" [(ngModel)]="p.Matricula"
                                placeholder="Matrícula..." oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div *ngIf="!p.updating && !p.creating" (click)="p.updating = !p.updating" class="icons-column">
                            <button class="btn-l-blue"><i class="fas fa-pen fa-1x"></i></button>
                        </div>
                        <div *ngIf="p.updating || p.creating" class="icons-column">
                            <div *ngIf="p.updating" (click)="deletePlaca(i,false)">
                                <button class="btn-l-blue"><i class="fas fa-trash-alt fa-2x"></i></button>
                            </div>
                            <div *ngIf="p.creating" (click)="cancelPlaca(i,false)">
                                <button class="btn-l-blue"><i class="fas fa-times fa-2x"></i></button>
                            </div>
                            <div *ngIf="p.updating || p.creating" (click)="updatePlaca(i,false)">
                                <button class="btn-l-blue"><i class="fas fa-check-circle fa-2x"></i></button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="places.length<user.NumPlaces && (places.length === 0 || !places[places.length - 1].creating)"
                        class="container-matricula container-white" style="height: fit-content; padding: 40px 20px;">
                        <button class="btn-transparent add-matricula" (click)="addPlaca()">
                            <i class="fas fa-plus fa-1x"></i>
                            <span>Afegeix un vehicle</span>
                        </button>
                    </div>
                </div>

                <h4>Historial de matrícules propies</h4>

                <div id="searcher">
                    <form [formGroup]="filterForm_taula_vehicles">
                        <div class="filters-wrapper">
                            <div class="filters-items">
                                <mat-form-field class="name-items" appearance="outline">
                                    <mat-label>Matrícula</mat-label>
                                    <input matInput placeholder="4512KSD" formControlName="search"
                                        (input)="this.onFilterSearch($event,$event.target.value,false)">
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label>Interval matrícula oberta</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input matStartDate placeholder="Inici" formControlName="start_date">
                                        <input matEndDate placeholder="Fi" formControlName="end_date"
                                            (dateChange)="changeTableGeneric(false)">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                </mat-form-field>

                                <mat-form-field appearance="outline">
                                    <mat-label for="status">Estat actual: </mat-label>
                                    <select id="status" matNativeControl formControlName="status"
                                        (change)="changeTableGeneric(false)">
                                        <option value="0" default>Tot</option>
                                        <option value="1">Obertes</option>
                                        <option value="2">Tancades</option>
                                    </select>
                                </mat-form-field>
                            </div>
                            <button class="reset-search-button" (click)="clearFilters(false)">
                                <i class="fas fa-redo"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div id="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Matrícula</th>
                                <th>Data Alta</th>
                                <th>Hora Inici</th>
                                <th>Data Baixa</th>
                                <th>Hora Baixa</th>
                                <th>Estat Actual</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of tabla_vehicles; let i = index;" #row>
                                <td>
                                    <span>{{ p.Matricula }}</span>
                                </td>
                                <td>
                                    <span>{{ dateFormat(p.DataAlta) }}</span>
                                </td>
                                <td>
                                    <span>{{ p.HoraAlta }}</span>
                                </td>
                                <td>
                                    <span *ngIf="p.Baixa">{{ dateFormat(p.DataBaixa) }}</span>
                                    <span *ngIf="!p.Baixa"> - </span>
                                </td>
                                <td>
                                    <span *ngIf="p.Baixa">{{ p.HoraBaixa }}</span>
                                    <span *ngIf="!p.Baixa"> - </span>
                                </td>
                                <td>
                                    <span *ngIf="checkIfOpen(p)" style="color: #3D7109DE">OBERT</span>
                                    <span *ngIf="!checkIfOpen(p)" style="color: #B71D1DDE">TANCAT</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="filter">
                    <form [formGroup]="filterForm_taula_vehicles">
                        <div class="paginator">
                            <div class="items-per-page">
                                <label for="page_size">Elements</label>
                                <select id="page_size" formControlName="page_size" (change)="changeTableGeneric(false)">
                                    <option [value]="+5">5</option>
                                    <option [value]="+10">10</option>
                                    <option [value]="+50">50</option>
                                </select>
                            </div>

                            <button class="grey icon" [disabled]="filterForm_taula_vehicles.value['page_number']==0"
                                (click)="previousPage(false)">
                                <i class="fas fa-chevron-left"></i>
                            </button>
                            <button class="grey icon"
                                [disabled]="(filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size'] >= filterForm_taula_vehicles.value['total']"
                                (click)="nextPage(false)">
                                <i class="fas fa-chevron-right"></i>
                            </button>
                            <span class="pagination-counter">{{
                                ((filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size'])-filterForm_taula_vehicles.value['page_size']+1
                                }} - {{
                                (filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size']
                                > filterForm_taula_vehicles.value['total'] ? filterForm_taula_vehicles.value['total'] :
                                (filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size']}}
                                de {{ filterForm_taula_vehicles.value['total'] }}
                            </span>

                        </div>
                    </form>
                </div>

                <h3 style="margin-top: 40px; font-weight: 600;" *ngIf="user.Comerciant">Transports de mercaderies</h3>
                <div *ngIf="user.Comerciant" style="margin-bottom: 20px;">
                    Els transports de mercaderies tindrán permesa l’entrada durant 30 minuts
                </div>
                <div class="matricules" *ngIf="user.Comerciant">
                    <div *ngFor="let p of places_comerciant; let i = index" class="container-matricula container-white"
                        [ngClass]="{'updating': p.updating || p.creating}">
                        <div style="flex-direction: column; color: #00000099;">
                            <span>Mercaderies {{i+1}}</span>
                            <div *ngIf="!p.updating && !p.creating" class="matricula">{{p.Matricula}}</div>
                            <input *ngIf="p.updating || p.creating" class="matricula" [(ngModel)]="p.Matricula"
                                placeholder="Matrícula..." oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div *ngIf="p.creating" class="icons-column">
                            <div (click)="cancelPlaca(i,true)">
                                <button class="btn-l-blue"><i class="fas fa-times fa-2x"></i></button>
                            </div>
                            <div (click)="updatePlaca(i,true)">
                                <button class="btn-l-blue"><i class="fas fa-check-circle fa-2x"></i></button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="places_comerciant.length === 0" class="container-matricula container-white"
                        style="height: fit-content; padding: 40px 20px;">
                        <button class="btn-transparent add-matricula" (click)="addPlaca(true)">
                            <i class="fas fa-plus fa-1x"></i>
                            <span>Afegeix un vehicle</span>
                        </button>
                    </div>
                </div>

                <h4>Historial de matrícules de mercaderies</h4>
                <div id="searcher">
                    <form [formGroup]="filterForm_taula_comerciant">
                        <div class="filters-wrapper">
                            <div class="filters-items">
                                <mat-form-field class="search-item" appearance="outline">
                                    <mat-label>Matrícula:</mat-label>
                                    <input matInput placeholder="4512KSD" formControlName="search"
                                        (input)="this.onFilterSearch($event,$event.target.value,true)">
                                </mat-form-field>

                                <mat-form-field class="search-item" appearance="outline">
                                    <mat-label>Interval matrícula oberta</mat-label>
                                    <mat-date-range-input [rangePicker]="picker2">
                                        <input matStartDate placeholder="Inici" formControlName="start_date">
                                        <input matEndDate placeholder="Fi" formControlName="end_date"
                                            (dateChange)="changeTableGeneric(true)">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>

                                    <mat-date-range-picker #picker2></mat-date-range-picker>
                                </mat-form-field>

                                <mat-form-field class="search-item" appearance="outline">
                                    <mat-label for="status">Estat actual: </mat-label>
                                    <select id="status" matNativeControl formControlName="status"
                                        (change)="changeTableGeneric(true)">
                                        <option value="0" default>Totes</option>
                                        <option value="1">Obertes</option>
                                        <option value="2">Tancades</option>
                                    </select>
                                </mat-form-field>
                            </div>
                            <button class="reset-search-button" (click)="clearFilters(true)"><i
                                    class="fas fa-redo"></i></button>

                        </div>
                    </form>
                </div>
                <div id="table-container" *ngIf="user.Comerciant">
                    <table>
                        <thead>
                            <tr>
                                <th>Matrícula</th>
                                <th>Data</th>
                                <th>Hora Inici</th>
                                <th>Data Baixa</th>
                                <th>Hora Fi</th>
                                <th>Estat</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of tabla_comerciant; let i = index;" #row>
                                <td>
                                    <span>{{ p.Matricula }}</span>
                                </td>
                                <td>
                                    <span>{{ dateFormat(p.DataAlta) }}</span>
                                </td>
                                <td>
                                    <span>{{ p.HoraAlta }}</span>
                                </td>
                                <td>
                                    <span>{{ dateFormat(p.DataBaixa) }}</span>
                                </td>
                                <td>
                                    <span>{{ p.HoraBaixa }}</span>
                                </td>
                                <td>
                                    <span *ngIf="checkIfOpen(p)" style="color: #3D7109DE">OBERT</span>
                                    <span *ngIf="!checkIfOpen(p)" style="color: #B71D1DDE">TANCAT</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="filter" *ngIf="user.Comerciant">
                    <form [formGroup]="filterForm_taula_comerciant">
                        <div class="paginator">
                            <div class="items-per-page">
                                <label for="page_size">Elements</label>
                                <select id="page_size" formControlName="page_size" (change)="changeTableGeneric(true)">
                                    <option [value]="+5">5</option>
                                    <option [value]="+10">10</option>
                                    <option [value]="+50">50</option>
                                </select>
                            </div>

                            <button class="grey icon" [disabled]="filterForm_taula_comerciant.value['page_number']==0"
                                (click)="previousPage(true)">
                                <i class="fas fa-chevron-left"></i>
                            </button>
                            <button class="grey icon"
                                [disabled]="(filterForm_taula_comerciant.value['page_number']+1)*filterForm_taula_comerciant.value['page_size'] >= filterForm_taula_comerciant.value['total']"
                                (click)="nextPage(true)">
                                <i class="fas fa-chevron-right"></i>
                            </button>
                            <span class="pagination-counter">{{
                                ((filterForm_taula_comerciant.value['page_number']+1)*filterForm_taula_comerciant.value['page_size'])-filterForm_taula_comerciant.value['page_size']+1
                                }} - {{
                                (filterForm_taula_comerciant.value['page_number']+1)*filterForm_taula_comerciant.value['page_size']
                                > filterForm_taula_comerciant.value['total'] ?
                                filterForm_taula_comerciant.value['total'] :
                                (filterForm_taula_comerciant.value['page_number']+1)*filterForm_taula_comerciant.value['page_size']}}
                                de {{ filterForm_taula_comerciant.value['total'] }}
                            </span>

                        </div>
                    </form>
                </div>
            </div>




        </div>
    </div>
</div>