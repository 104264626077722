import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form:FormGroup;

  loading:boolean = false;
  showPassword:boolean = false;
  capcha:boolean = false;

  constructor(private fb: FormBuilder,
              private auth:AuthenticationService,
              private api:ApiService,
              private router:Router,
              private toastr: ToastrService) {
    this.form = fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      recaptchaReactive : ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  login() {
    var l = document.getElementById("loader");
    l.style.display = "flex";
    this.loading = true;
    this.auth.login(this.form.value["email"], this.form.value["password"]).subscribe(
      res => {
        l.style.display = "none";
        this.toastr.success("Has iniciat sessió correctament.", "Login correcte");
        if (res?.user?.admin) this.router.navigate(["/GestorMat"], { queryParams: { start: 1} });
        else this.router.navigate(["/gestio"], { queryParams: { start: 1} });
        this.loading = false;
      },
      (error:HttpErrorResponse) => {
        l.style.display = "none";
        if(error.status==401) {
          this.toastr.error("Si us plau, torni a intentar-ho", "Nom d'usuari o contrasenya no vàlid.");
        }
        else {
          this.toastr.error("Torna a intentar-ho més tard", "Hi ha un error al servidor.");
        }
        //this.form.reset();
        this.loading = false;
      }
    );
  }

  sendPasswordResetLink() {
    if(this.form.value["email"]==null || this.form.value["email"]=="") {
      this.toastr.error("Si us plau, entri el seu correu i torni a provar.", "");
    } else {
      var l = document.getElementById("loader");
      l.style.display = "flex";
      this.api.sendPasswordResetLink(this.form.value["email"]).subscribe(
        res => {
          l.style.display = "none";
          this.toastr.success("Si us plau, revisa la bústia de correu.", "");
          this.loading = false;
        },
        (error:HttpErrorResponse) => {
          l.style.display = "none";
          if(error.status==401) {
            this.toastr.error("Si us plau, torni a intentar-ho", "Correu no vàlid.");
          }
          else {
            this.toastr.error("Torna a intentar-ho més tard", "Hi ha un error al servidor.");
          }
          //this.form.reset();
          this.loading = false;
        }
      );

    }
  }

  resolved(captchaResponse: string) {
    this.capcha = true;
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

}
