import { Placa } from "./placa.model";

export class User {
    id:                number;
    password:            string;
    Adreca:            string;
    Baixa:             boolean;
    CodiPostal:        string;
    Cognoms:           string;
    Comerciant:        boolean;
    DataAlta:          Date;
    DataBaixa:         Date;
    Mobil:             string;
    NIF:               string;
    Nom:               string;
    NumPlaces:         number;
    places:            Placa[];
    places_comerciant: Placa[];
    Poblacio:          string;
    Tecnic:            boolean;
    admin:            boolean;
    Telefon:           string;
    email:             string;
    email_verified_at: Date;
    created_at:        Date;
    updated_at:        Date;
    show_all:          boolean = false;
}