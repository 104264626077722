<div class="dialog-container">
    <div class="dialog">
        <div>
            <h2>Historial matrícules</h2>
            <div (click)="cancel()" style="cursor: pointer;">
                <i class="fa fa-times fa-2x"></i>
            </div>
        </div>
        <div id="searcher">
            <form [formGroup]="filterForm_taula_vehicles">
                <div class="filters-wrapper">
                    <div class="filters-items">
                        <mat-form-field class="search-item" appearance="outline">
                            <mat-label>Matrícula</mat-label>
                            <input matInput placeholder="4512KSD" formControlName="search" (input)="this.onFilterSearch($event,$event.target.value)">
                        </mat-form-field>
                        
                        <mat-form-field class="search-item"  appearance="outline" >
                            <mat-label>Interval Matrícula oberta</mat-label>
                            <mat-date-range-input  [rangePicker]="picker2">
                                <input matStartDate placeholder="Inici" formControlName="start_date"> 
                                <input matEndDate placeholder="Fi" formControlName="end_date" (dateChange)="changeTableGeneric()">
                                </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>

                            <mat-date-range-picker #picker2></mat-date-range-picker>
                        </mat-form-field>

                        <mat-form-field class="search-item items-type"  appearance="outline">
                            <mat-label for="status">Estat actual: </mat-label>
                            <select id="status"  matNativeControl formControlName="status" (change)="changeTableGeneric()" >
                                <option value="0" default>Tot</option>
                                <option value="1" >Obertes</option>
                                <option value="2" >Tancades</option>
                            </select>
                        </mat-form-field>

                        <mat-form-field class="search-item items-type"  appearance="outline">
                            <mat-label for="comerciant">Tipus: </mat-label>
                            <select id="comerciant"  matNativeControl formControlName="comerciant" (change)="changeTableGeneric()" >
                                <option *ngFor="let opt of comerciant_options" [ngValue]="opt.value">
                                    {{opt.viewValue}}
                                </option>
                                <!--option value={{0}} default>Totes</option>
                                <option value={{1}} > Permanent</option>
                                <option value={{2}} > Temporal</option-->
                            </select>
                        </mat-form-field>

                    </div>
                    <div>
                        <button class="reset-search-button" (click)="clearFilters()"><i class="fas fa-redo"></i></button>
                        <button class="reset-search-button" (click)="export()" style="margin-left: 1em;"><i class="fas fa-download"></i></button>
                    </div>

                </div>
            </form>
        </div>
        <div id="table-container" >
            <table>
                <thead>
                    <tr>
                        <th>Matrícula</th>
                        <th>Data Alta</th>
                        <th>Hora Inici</th>
                        <th>Data Baixa</th>
                        <th>Hora Fi</th>
                        <th>Estat</th>
                        <th>Tipus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let p of tabla_vehicles; let i = index;" #row>
                        <td>
                            <span>{{ p.Matricula }}</span>
                        </td>
                        <td>
                            <span>{{ dateFormat(p.DataAlta) }}</span>
                        </td>
                        <td>
                            <span>{{ p.HoraAlta }}</span>
                        </td>
                        <td>
                            <span *ngIf="!showEndDate(p)"> - </span>
                            <span *ngIf="showEndDate(p)">{{ dateFormat(p.DataBaixa) }}</span>

                        </td>
                        <td>
                            <span *ngIf="!showEndDate(p)"> - </span>
                            <span *ngIf="showEndDate(p)">{{ p.HoraBaixa }}</span>
                            
                        </td>
                        <td>
                            <span *ngIf="checkIfOpen(p)" style="color: #3D7109DE">OBERT</span>
                            <span *ngIf="!checkIfOpen(p)" style="color: #B71D1DDE">TANCAT</span>
                        </td>
                        <td>
                            <span *ngIf="checkIfComerciant(p)">MERCADERIA</span>
                            <span *ngIf="!checkIfComerciant(p)">PROPIA</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="filter" *ngIf="user.Comerciant">
            <form [formGroup]="filterForm_taula_vehicles">
                <div class="paginator">
                    <div class="items-per-page">
                        <label for="page_size">Elements</label>
                        <select id="page_size" formControlName="page_size" (change)="changeTableGeneric()">
                            <option [value]="+5">5</option>
                            <option [value]="+10">10</option>
                            <option [value]="+50">50</option>
                        </select>
                    </div>
                    
                    <button class="grey icon" [disabled]="filterForm_taula_vehicles.value['page_number']==0" (click)="previousPage()"><i class="fas fa-chevron-left"></i></button>
                    <button class="grey icon" [disabled]="(filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size'] >= filterForm_taula_vehicles.value['total']" (click)="nextPage()"><i class="fas fa-chevron-right"></i></button>
                    <span class="pagination-counter">{{ ((filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size'])-filterForm_taula_vehicles.value['page_size']+1 }} - {{ (filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size'] > filterForm_taula_vehicles.value['total'] ? filterForm_taula_vehicles.value['total'] : (filterForm_taula_vehicles.value['page_number']+1)*filterForm_taula_vehicles.value['page_size']}} de {{ filterForm_taula_vehicles.value['total'] }}</span>
                    
                </div>
            </form>
        </div>
    </div>
</div>