<div id="main">
    <div id="container">
        <h1>AJUNTAMENT DE PALS</h1>
    
        <form id="login-form" [formGroup]="form" (ngSubmit)="login()">
            <h2>Inici de sessió</h2>

            <div>
                <span class="icon-input"><i class="fa fa-user fa-1x"> </i></span>
                <input type="text" placeholder="Usuari" name="email" formControlName="email" required>
            </div>
    
            <div>
                <span class="icon-input"><i class="fa fa-lock fa-1x"> </i></span>
                <input id="password" [type]="showPassword ? 'text' : 'password'" placeholder="Contrasenya" formControlName="password" name="password" required>
                <button type="button" id="showHidePassword" (click)="showHidePassword()"><i class="fa fa-eye fa-1x"> </i></button>
            </div>
            <span class="little" (click)="sendPasswordResetLink()"><a>He oblidat la contrasenya</a></span>

            <re-captcha (resolved)="resolved($event)" 
              formControlName="recaptchaReactive" 
              siteKey="6LfBbAwbAAAAAIhnhfzrr0NqBwA1v1RlKAnwpmZQ"
              style="margin: 10px auto 0px auto;">  
            </re-captcha>
            
            <button type="submit" class="btn-blue" [disabled]="!capcha">INICIAR SESSIÓ</button> 
            <!-- 
            <button type="submit" class="btn-blue" >INICIAR SESSIÓ</button> 
        -->
            <br>
            <span id="register-redirection" class="little" style="text-decoration: none; margin: 0px auto; font-style: italic;">Encara no ets usuari?</span>
            <span class="little" style="margin: 0px auto; font-style: italic;"><a href="https://www.pals.cat" style="color: #00000099;">Informació per al registre d'usuari</a></span>
        </form>

    </div>
</div>