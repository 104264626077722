import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';



@Injectable()
export class AppGuard implements CanActivate {
  constructor(private router: Router,
              private auth:AuthenticationService) { }
    
  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
    const fromLogin:boolean = route.queryParamMap.get("start")=="1";
    const loggedIn:boolean = await this.auth.refreshToken().toPromise() || fromLogin;
    
    if(!loggedIn) {
      return this.logout();
    }

    return true;
  }

  private logout() {
    this.router.navigate(['/login']);
    return false;
  }
}