import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { Placa } from 'src/app/models/placa.model';
import { HistoryItem } from 'src/app/models/history-item.model';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})

export class MainComponent implements OnInit {
  
  show_hist:boolean =  false;
  /* Usuaris */
  filterForm:FormGroup;
  filterUserHistoryForm:FormGroup;
  users:User[];
  me:User;
  show_dialog_new_user: boolean = false;
  show_dialog_user_history: boolean = false;
  selected_user: User = null;
  order_by: String = "Nom";
  order_direction: String = "ASC";
  /*Matricules*/
  filterForm_taula_vehicles :FormGroup;
  tabla_vehicles:HistoryItem[];
  comerciant_options:{ viewValue: string; value: boolean; }[];


  constructor(private userService:UserService,
              private apiService: ApiService,
              private toastr: ToastrService,
              private fb: FormBuilder,
              private authService: AuthenticationService,
              private router:Router) {
      this.filterForm = fb.group({
        page_size: [10, Validators.required],
        page_number: [0, Validators.required],
        user_id: null,
        name: ['', Validators.required],
        total: [0]
      });
      this.filterUserHistoryForm= fb.group({
        page_size: [5, Validators.required],
        page_number: [0, Validators.required],
        user_id: null,
        comerciant: null,
        start_date: null,
        end_date: null,
        search: null,
        status: [0, Validators.required],
        total: [0]
      });
      this.comerciant_options = [
        {
          viewValue: "Tot",
          value: null
        },
        {
          viewValue: "Propies",
          value: false
        },
        {
          viewValue: "Mercaderies",
          value: true
        }
      ];
  
      this.filterForm_taula_vehicles = fb.group({
        page_size: [5, Validators.required],
        page_number: [0, Validators.required],
        comerciant: null,
        start_date: null,
        end_date: null,
        search: null,
        status: [0, Validators.required],
        total: [0]
      });
  }

  ngOnInit(): void {
    this.me = this.userService.getCurrentUser();
    if(!this.me?.admin) this.router.navigate(["/gestio"]);
    this.fetchUsers();

    this.filterForm_taula_vehicles = this.fb.group({
      page_size: [10, Validators.required],
      page_number: [0, Validators.required],
      comerciant: null,
      start_date: null,
      end_date: null,
      search: null,
      status: [0, Validators.required],
      total: [0]
    });
    this.fetchPlaces();

  }

  fetchUsers() {
    var params = this.filterForm.value;
    params["order_by"] = this.order_by;
    params["order_direction"] = this.order_direction;

    this.apiService.getAllUsers(params).subscribe(
      data => {
        this.users = data.data;
        this.filterForm.patchValue({
          total: +data.total,
          page_number: +data.page_number,
          page_size: +data.page_size
        });
      },
      err => {
      }
    );
  }

  fetchPlaces() {
      this.apiService.getHistory(this.filterForm_taula_vehicles.value).subscribe(
        data => {
          this.tabla_vehicles = data.data;
          
          this.filterForm_taula_vehicles.patchValue({
            total: +data.total,
            page_number: +data.page_number,
            page_size: +data.page_size
          });
        }
      )
  }

  changeOrder(order_by) {
    this.order_by = order_by;
    this.order_direction = this.order_direction=="DESC" ? "ASC" : "DESC";
    this.fetchUsers();
  }

  showAll(index:number) {
    for(var i=0;i<this.users.length;i++) {
      if (i == index){
        this.users[i].show_all = !this.users[i].show_all;
      }
      else this.users[i].show_all = false;
    }
  }

  create(user:User) {
    this.show_dialog_new_user = false;
    if(user!=null) {
      var l = document.getElementById("loader");
      l.style.display = "flex";
      this.apiService.createUser(user).subscribe(
        data => {
          l.style.display = "none";
          this.users.splice(0,0,data);
        },
        err => {
          l.style.display = "none";
        }
      );
    }
  }

  update(user:User) {
    this.show_dialog_new_user = false;
    if(user!=null) {
      var l = document.getElementById("loader");
      l.style.display = "flex";
      this.apiService.updateUser(this.selected_user.id, user).subscribe(
        data => {
          l.style.display = "none";
          this.fetchUsers();
        },
        err => {
          l.style.display = "none";
        }
      );
    }
  }

  export(u:User) {
    if (u == null){
      const today = new Date();
      const yyyy = today.getFullYear().toString();
      let mm = (today.getMonth() + 1).toString(); // Months start at 0!
      let dd = today.getDate().toString();

      if (today.getDate() < 10) dd = '0' + dd;
      if (today.getMonth() + 1 < 10) mm = '0' + mm;
      this.exportexcel("LlistatMatriqulesParcial-" + yyyy + mm + dd );
    }
  }

  exportexcel(fileName: string) : void {
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        let map = new Map<string, string>() 
        let jsonObject = {};  
        map.forEach((value, key) => {  
            jsonObject[key] = value  
        }); 
        //const dades: any[] = map(this.tabla_vehicles, (history) => { Nom: history.Nom, Cognom: history.cognom   });

        var taulaexcel = this.tabla_vehicles.map((elem) => ({
            Nom: elem.Nom,
            Cognoms: elem.Cognoms,
            Telefon: elem.Telefon,
            Mobil: elem.Mobil,
            Email: elem.email,
            Matricula : elem.Matricula,
            DataAlta : elem.DataAlta.toString().substring(0,10),
            HoraInici : elem.DataAlta.toString().substring(11,19),
            DataBaixa : (!elem.Comerciant && !elem.Baixa) ? "-" : elem.DataBaixa.toString().substring(0,10),
            HoraFi : (!elem.Comerciant && !elem.Baixa) ? "-" : elem.DataBaixa.toString().substring(11,19),
            Comerciant : elem.Comerciant ? "COMERCIANT" : "PROPIA"
          }
        ));

        var ws = XLSX.utils.json_to_sheet(taulaexcel, 
          {header: ["Nom", "Cognoms", "Telefon", "Mobil", "Email", "Matricula", "DataAlta",	"HoraInici",	"DataBaixa",	"HoraFi",	"Comerciant"], 
          skipHeader: false});
      
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, fileName + '.xlsx');
  
  }

  createUserHistory(user:User) {
    this.show_dialog_user_history = false;
    if(user != null){
      var l = document.getElementById("loader");
      l.style.display = "flex";
    }


  }

  deleteUser() {
    if(this.selected_user!=null) {
      if(confirm("Segun que vols eliminar a l'usuari " + this.selected_user.Nom)) {
        var l = document.getElementById("loader");
        l.style.display = "flex";
        this.apiService.deleteUser(this.selected_user.id).subscribe(
          data => {
            l.style.display = "none";
            this.fetchUsers();
          },
          err => {
            l.style.display = "none";
          }
        );
      }
    }
  }

  filterUsers(name:String) {
    this.filterForm.controls['name'].setValue(name);
    this.fetchUsers();
  }

  nextPage() {
    this.changePage((+this.filterForm.value['page_number'])+1);
  }

  previousPage() {
    this.changePage((+this.filterForm.value['page_number'])-1);
  }

  private changePage(page_num:number) {
    this.filterForm.patchValue({ page_number: page_num });
    this.fetchUsers();
  }

  changePageSize() {
    this.fetchUsers();
  }

  logout() {
    this.authService.logout();
  }

  /*History*/
  changeTableGeneric() {
    this.fetchPlaces();
  }

  clearFilters(){
    this.filterForm_taula_vehicles.patchValue({ search: null });
    this.filterForm_taula_vehicles.patchValue({ start_date: null });
    this.filterForm_taula_vehicles.patchValue({ end_date: null });
    this.filterForm_taula_vehicles.patchValue({ status: 0 });
    this.filterForm_taula_vehicles.patchValue({ comerciant: null})
    this.fetchPlaces()
  }

  filterSearch(text:string){
    this.filterForm_taula_vehicles.patchValue({ search: text.replace(/\s/g, "") });
    this.fetchPlaces();
  }

  timeout: any = null;
  onFilterSearch(event: any, text:string) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(() => {
      if (event.keyCode != 13) {
        this.filterSearch(text);
      }
    }, 500);
  }

  dateFormat(d:Date) {
    return d[8]+d[9]+"/"+d[5]+d[6]+"/"+d[0]+d[1]+d[2]+d[3];
  }

  checkIfOpen(p:Placa) : boolean {
    if (!p.Comerciant){
      return !p.Baixa;
    } else{
      var today : Date = new Date(); 
      var da : Date = new Date(p.DataAlta.toString());
      var db : Date = new Date(p.DataAlta.toString());
      
      da.setHours(Number(p.HoraAlta.toString().substring(0,2)),
      Number(p.HoraAlta.toString().substring(3,5)));
      db.setHours(Number(p.HoraBaixa.toString().substring(0,2)),
      Number(p.HoraBaixa.toString().substring(3,5)))
      
      return da.getTime() < today.getTime() && db.getTime() > today.getTime() ;
    }     
  }

  checkIfComerciant(p:Placa) : boolean {
    return p.Comerciant;
  }

  nextPageV() {
    this.changePageV((+this.filterForm_taula_vehicles.value['page_number'])+1);
  }

  previousPageV() {
    this.changePageV((+this.filterForm_taula_vehicles.value['page_number'])-1);
  }

  private changePageV(page_num:number) {
    this.filterForm_taula_vehicles.patchValue({ page_number: page_num });
    this.fetchPlaces();
  }

  showEndDate(p:Placa):boolean{
    if (!p.Comerciant && !p.Baixa)
      return false;
    else 
      return true;
  }

}
