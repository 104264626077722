import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UtilsService } from '../services/utils.service';
import { TokenService } from '../services/token.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(private utilsService:UtilsService,
              private toastr:ToastrService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tractable:boolean = (request.url.indexOf(environment.laravel_url) != -1) && request.url.indexOf("auth")==-1;

    //tractem request
    if(tractable) {
        this.utilsService.enableLoading();
    }

    //tractem response
    return next.handle(request).pipe(
        catchError((error:HttpErrorResponse) => {
            if(tractable) {
                this.utilsService.disableLoading();
                let title:string;
                switch(request.method) {
                    case 'GET':
                        title = "Error obteniendo datos...";
                        break;
                    case 'POST':
                        title = "Error creando...";
                        break;
                    case 'PUT':
                        title = "Error actualizando...";
                        break;
                    case 'DELETE':
                        title = "Error borrando...";
                        break;
                    default:
                        title = "Error";
                        break;
                }
                const message = error.error ? (`Error ${error.status}: ${error.error.error ? error.error.error : error.error.message}`) : (`Error ${error.status}: ${error.message}`)
                this.toastr.error(message, title);
            }
            return throwError(error);
        }),
        map((event:HttpEvent<any>) => {
            if(event instanceof HttpResponse && tractable) {
                this.utilsService.disableLoading();
                switch (request.method) {
                    case "POST":
                        this.toastr.success("Se ha creado correctamente.");
                        break;
                    case "PUT":
                        this.toastr.success("Se ha actualizado correctamente");
                        break;
                    case "DELETE":
                        this.toastr.success("Se ha borrado correctamente");
                        break;
                }
            }
            return event;
        })
    );
  }
}